import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { AuthService } from "../auth/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private authService: AuthService = inject(AuthService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const excludedUrls = ['/login'];
    
    if (excludedUrls.some(url => req.url.includes(url))) {
      return next.handle(req);
    }
    if (this.authService.accessToken) {
      const clonedRequest = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.accessToken}`
        }
      });
      return next.handle(clonedRequest);
    } else {
      return throwError(() => new HttpErrorResponse({ status: 401, statusText: 'El token no fue proveeido' }));
    }
  }


}
